<template>
  <div class="operateLog-main">
    <div class='main-title'>
      <div class="main-label">操作日志</div>
    </div>
    <el-table
    v-loading='loading'
    :data="postData"
    class="operateLog-table"
    :header-cell-style="{background:'#fafafa'}"
    >
      <el-table-column label="用户名称" prop="saas_user_name"></el-table-column>
      <el-table-column label="菜单名称" prop="menu_name" ></el-table-column>
      <el-table-column label="状态" >
        <template #default="{ row }">
          <el-tag v-if="row.type=='1'">新增</el-tag>
          <el-tag v-else-if="row.type=='2'" type="success">修改</el-tag>
          <el-tag v-else-if="row.type=='3'" type="danger">删除</el-tag>
          <el-tag v-else type="info">列表</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作时间" prop="created_at"  align="left" width="200px"></el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getOperateLog } from '@/api/saasManage'
import { Tag } from 'element-ui';
export default {
  components:{
    'el-tag':Tag,
  },
  data() {
    return {
      parentSelectId:[],
      loading:false,
      postData:[],
      drawer:false,
      dialog:false,
      editData:{},
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      search: '',
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * @desc:筛选
     */
    onSearch(data){
      this.search = data;
      this.getData();
    },
    onRefresh(){
      this.search = '';
      this.getData();
    },
     /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},

    // /**
		//  * @desc: 得到字典管理列表
		//  */
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        saas_user_name: this.search
      };
      getOperateLog(params).then(res=>{
        if(res.data && res.data.code === 200){
          this.postData = res.data.data.data;
          this.pager = {
            page: res.data.data.current_page,
            size: res.data.data.per_page,
            total: res.data.data.total
          };
        }
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },
};
</script>
<style lang='less'>
@import "../../../../css/manageMain.less";
.operateLog-main {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 8px;
  .operateLog-table{
    width: 100%;
    flex: 1;
    padding: 0 15px;
    overflow: auto;
  }
}
</style>